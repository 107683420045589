import React, { useMemo, useState } from 'react';
import { TailwindColors, getTailwindColor } from '~/utils/style/tailwind';

//icons
import ArrowDown from '~/public/common/icons/arrowDown.svg';
import ArrowLeft from '~/public/common/icons/arrowLeft.svg';
import ArrowLeftSm from '~/public/common/icons/arrowLeftSm.svg';
import Checkmark from '~/public/common/icons/checkmark.svg';
import CheckmarkCircle from '~/public/common/icons/checkmarkCirlcle.svg';
import ChevronDown from '~/public/common/icons/chevronDown.svg';
import ChevronUp from '~/public/common/icons/chevronUp.svg';
import Chrome from '~/public/common/icons/chrome.svg';
import Clock from '~/public/common/icons/clock.svg';
import Controller from '~/public/common/icons/controller.svg';
import Copy from '~/public/common/icons/copy.svg';
import Cross from '~/public/common/icons/cross.svg';
import CrossCircle from '~/public/common/icons/crossCircle.svg';
import CrownSolid from '~/public/common/icons/crownSolid.svg';
import Discord from '~/public/common/icons/discord.svg';
import DoubleChevron from '~/public/common/icons/doubleChevron.svg';
import DoubleChevronSm from '~/public/common/icons/doubleChevronSm.svg';
import Download from '~/public/common/icons/download.svg';
import Edit from '~/public/common/icons/edit.svg';
import Exit from '~/public/common/icons/exit.svg';
import Expand from '~/public/common/icons/expand.svg';
import Eye from '~/public/common/icons/eye.svg';
import EyeOff from '~/public/common/icons/eyeOff.svg';
import Firefox from '~/public/common/icons/firefox.svg';
import Fullscreen from '~/public/common/icons/fullscreen.svg';
import FullscreenDisable from '~/public/common/icons/fullscreenDisable.svg';
import FullscreenEnable from '~/public/common/icons/fullscreenEnable.svg';
import Gear from '~/public/common/icons/gear.svg';
import Google from '~/public/common/icons/google.svg';
import HeadphonesDisabled from '~/public/common/icons/headphonesDisabled.svg';
import HeadphonesEnabled from '~/public/common/icons/headphonesEnabled.svg';
import InfoCircle from '~/public/common/icons/infoCircle.svg';
import LightbulbColor from '~/public/common/icons/lightbulbColor.svg';
import LightbulbColorBright from '~/public/common/icons/lightbulbColorBright.svg';
import Link from '~/public/common/icons/link.svg';
import Linkedin from '~/public/common/icons/linkedin.svg';
import Live from '~/public/common/icons/live.svg';
import LoadingSpinner from '~/public/common/icons/loadingSpinner.svg';
import Menu from '~/public/common/icons/menu.svg';
import MenuDiamond from '~/public/common/icons/menu_diamond.svg';
import MicrohponeDisabled from '~/public/common/icons/microphoneDisabled.svg';
import MicrophoneDisabledCircleSmall from '~/public/common/icons/microphoneDisabledCircleSmall.svg';
import MicrohponeEnabled from '~/public/common/icons/microphoneEnabled.svg';
import Minus from '~/public/common/icons/minus.svg';
import MinusCircle from '~/public/common/icons/minusCircle.svg';
import MinusCircleSm from '~/public/common/icons/minusCircleSm.svg';
import Okay from '~/public/common/icons/okay.svg';
import Outlook from '~/public/common/icons/outlook.svg';
import PickTeams from '~/public/common/icons/pick_teams.svg';
import Pin from '~/public/common/icons/pin.svg';
import PinOff from '~/public/common/icons/pinOff.svg';
import Play from '~/public/common/icons/play.svg';
import PlayOutline from '~/public/common/icons/playOutline.svg';
import Plus from '~/public/common/icons/plus.svg';
import PlusCircle from '~/public/common/icons/plusCircle.svg';
import PopoverArrow from '~/public/common/icons/popoverArrow.svg';
import Power from '~/public/common/icons/power.svg';
import Question from '~/public/common/icons/question.svg';
import Search from '~/public/common/icons/search.svg';
import Settings from '~/public/common/icons/settings.svg';
import Shuffle from '~/public/common/icons/shuffle.svg';
import Speaker from '~/public/common/icons/speakerFill.svg';
import SpeakerMuteCircleSmall from '~/public/common/icons/speakerMuteCircleSmall.svg';
import SpeakerMute from '~/public/common/icons/speakerMuteFill.svg';
import Split from '~/public/common/icons/split.svg';
import StandardScreen from '~/public/common/icons/standardscreen.svg';
import Star from '~/public/common/icons/star.svg';
import Star2 from '~/public/common/icons/star2.svg';
import ThreeDotsHorizontal from '~/public/common/icons/threeDotsHorizontal.svg';
import ThreeDotsVertical from '~/public/common/icons/threeDotsVertical.svg';
import Trophy from '~/public/common/icons/trophy.svg';
import Twitter from '~/public/common/icons/twitter.svg';
import Upload from '~/public/common/icons/upload.svg';
import User from '~/public/common/icons/user.svg';
import UserGroup from '~/public/common/icons/usergroup.svg';
import UserList from '~/public/common/icons/userlist.svg';
import VolumeFullCircleFill from '~/public/common/icons/volumeFullCircleFill.svg';
import VolumeFullOutline from '~/public/common/icons/volumeFullOutline.svg';
import VolumeMidOutline from '~/public/common/icons/volumeMidOutline.svg';
import VolumeMuteCircleFill from '~/public/common/icons/volumeMuteCircleFill.svg';
import VolumeMuteOutline from '~/public/common/icons/volumeMuteOutline.svg';
import VolumeQuietOutline from '~/public/common/icons/volumeQuietOutline.svg';
import WarningCircle from '~/public/common/icons/warningCircle.svg';
import WarningTriangle from '~/public/common/icons/warningTriangle.svg';
import Wifi from '~/public/common/icons/wifi.svg';
import WifiSmall from '~/public/common/icons/wifiSmall.svg';

const svgs = {
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowLeftSm: ArrowLeftSm,
  checkmark: Checkmark,
  checkmarkCircle: CheckmarkCircle,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  chrome: Chrome,
  clock: Clock,
  controller: Controller,
  copy: Copy,
  cross: Cross,
  crossCircle: CrossCircle,
  crownSolid: CrownSolid,
  discord: Discord,
  doubleChevron: DoubleChevron,
  doubleChevronSm: DoubleChevronSm,
  download: Download,
  edit: Edit,
  exit: Exit,
  expand: Expand,
  eye: Eye,
  eyeOff: EyeOff,
  firefox: Firefox,
  fullscreen: Fullscreen,
  fullscreenDisable: FullscreenDisable,
  fullscreenEnable: FullscreenEnable,
  gear: Gear,
  google: Google,
  headphonesDisabled: HeadphonesDisabled,
  headphonesEnabled: HeadphonesEnabled,
  infoCircle: InfoCircle,
  lightbulbColor: LightbulbColor,
  lightbulbColorBright: LightbulbColorBright,
  link: Link,
  linkedin: Linkedin,
  live: Live,
  loadingSpinner: LoadingSpinner,
  menu_diamond: MenuDiamond,
  menu: Menu,
  microphoneDisabled: MicrohponeDisabled,
  microphoneDisabledCircle: MicrophoneDisabledCircleSmall,
  microphoneEnabled: MicrohponeEnabled,
  minus: Minus,
  minusCircle: MinusCircle,
  minusCircleSm: MinusCircleSm,
  okay: Okay,
  outlook: Outlook,
  pickTeams: PickTeams,
  pin: Pin,
  pinOff: PinOff,
  play: Play,
  playOutline: PlayOutline,
  plus: Plus,
  plusCircle: PlusCircle,
  popoverArrow: PopoverArrow,
  power: Power,
  question: Question,
  search: Search,
  settings: Settings,
  shuffle: Shuffle,
  speaker: Speaker,
  speakerMute: SpeakerMute,
  speakerMuteCircleSmall: SpeakerMuteCircleSmall,
  split: Split,
  standardscreen: StandardScreen,
  star: Star,
  star2: Star2,
  threeDotsHorizontal: ThreeDotsHorizontal,
  threeDotsVertical: ThreeDotsVertical,
  trophy: Trophy,
  twitter: Twitter,
  upload: Upload,
  user: User,
  userGroup: UserGroup,
  userList: UserList,
  volumeFullCircleFill: VolumeFullCircleFill,
  volumeFullOutline: VolumeFullOutline,
  volumeMidOutline: VolumeMidOutline,
  volumeMuteCircleFill: VolumeMuteCircleFill,
  volumeMuteOutline: VolumeMuteOutline,
  volumeQuietOutline: VolumeQuietOutline,
  warningCircle: WarningCircle,
  warningTriangle: WarningTriangle,
  wifi: Wifi,
  wifiSmall: WifiSmall,
};

export type AvailableIcons = keyof typeof svgs;

export interface IconProps {
  color?: TailwindColors;
  hoveredColor?: TailwindColors;
  modify?: 'stroke' | 'fill' | 'both';
  name: AvailableIcons;
  height?: number;
  width?: number;
  size?: 'small' | 'regular' | 'medium' | 'large' | 'xl';
}

export const Icon: React.FC<IconProps> = ({
  color,
  hoveredColor,
  name,
  height,
  width,
  modify = 'fill',
  size = 'regular',
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  if (hovered) color = hoveredColor || color;
  const iconSize = useMemo(() => {
    if (size === 'small') return 'scale-50';
    if (size === 'medium') return 'scale-75';
    if (size === 'large') return 'scale-125';
    if (size === 'xl') return 'scale-150';
    return '';
  }, [size]);

  const viewboxSize = useMemo(() => {
    if (size === 'small') return '12';
    if (size === 'medium') return '18';
    if (size === 'large') return '30';
    if (size === 'xl') return '36';
    return '24';
  }, [size]);

  const icon = useMemo(() => {
    if (!svgs[name]) return null;
    const element = svgs[name]();

    const children = React.Children.toArray(element.props.children);
    let paths: typeof children = [];
    const otherElements: typeof children = [];

    for (const child of children) {
      const isPath =
        (child as any).type === 'path' || (child as any).props.children.type === 'path';
      const isNoColorModify = (child as any).props && (child as any).props['data-no-modify'];

      if (isPath && !isNoColorModify) {
        paths.push(child);
      } else {
        otherElements.push(child);
      }
    }

    if (color) {
      let iconColor = '';
      if (
        (color as string).split('').includes('-') &&
        !(color as string).startsWith('functional')
      ) {
        const [clr, weight] = (color as string).split('-');
        const twColor = getTailwindColor(clr);
        iconColor = twColor[weight];
      } else {
        iconColor = getTailwindColor(color) || color;
      }

      // if iconColor contains '<alpha-value>', replace with 'var(--tw-bg-opacity)'
      // Fix for css variable colors
      if (iconColor.includes('<alpha-value>')) {
        iconColor = iconColor.replace('<alpha-value>', 'var(--tw-bg-opacity)');
      }

      paths = paths.map((child) => {
        const modifyColor: Partial<Record<'stroke' | 'fill', string>> = {};

        switch (modify) {
          case 'fill':
            modifyColor.fill = iconColor;
            break;
          case 'stroke':
            modifyColor.stroke = iconColor;
            break;
          default:
            modifyColor.fill = iconColor;
            modifyColor.stroke = iconColor;
            break;
        }

        return React.cloneElement(child as any, {
          ...modifyColor,
          className: `${iconSize}`,
        });
      });
    }

    return React.cloneElement(
      element,
      {
        viewBox: `0 0 ${viewboxSize} ${viewboxSize}`,
        height: height || viewboxSize || 24,
        width: width || viewboxSize || 24,
      },
      [...otherElements, ...paths]
    );
  }, [name, height, width, modify, color, iconSize, viewboxSize]);

  return (
    <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {icon}
    </div>
  );
};
